export const ERRORS: any = {
  'Invalid request': 'Requisição inválida',
  'File not provided in the request': 'Arquivo não fornecido na requisição',
  'Internal Server Error': 'Erro interno do servidor',
  'Not Found': 'Não encontrado',
  'JWT Token is missing': 'Token JWT não encontrado',
  'Invalid JWT Token': 'Token JWT inválido',
  'Profile name already used': 'Nome de perfil já utilizado',
  'Reimbursement already approved': 'Reembolso já aprovado',
  'The refund amount cannot be greater than the invoice amount': 'O valor do reembolso não pode ser maior que o valor da fatura',
  'This refund has only one status': 'Este reembolso possui apenas um status',
  'You can only approve reimbursements with status "CONFORMING" and "NON CONFORMING"': 'Você só pode aprovar reembolsos com status "CONFORME" e "NÃO CONFORME"',
  'You are not allowed to search by this user': 'Você não tem permissão para pesquisar por este usuário',
  'The name is already been used by other rule': 'O nome da regra já existe',
  'Incorrect password': 'Senha incorreta',
  'Email address already used': 'Endereço de e-mail já utilizado',
  'Collaborator Id already used': 'ID do colaborador já utilizado',
  'National Id Card already used': 'Número de identificação (CPF) já existente',
  'Login already used': 'Login já utilizado',
  'Incorrect email/password combination': 'Combinação de e-mail/senha incorretos',
  'Invalid Token': 'Acesso inválido',
  'Expired Token': 'Acesso expirado',
  'User not found with this token': 'Usuário não encontrado com este token',
  'User not found': 'Usuário não encontrado',
  'This user is blocked': 'Este usuário está bloqueado',
  'Error when trying to run batch approval': 'Erro ao tentar executar a aprovação em lote',
  'Image text not extracted': 'Texto da imagem não extraído',
  'File extension must be .csv': 'A extensão do arquivo deve ser .csv',
  'ntauth': 'Você não possui autorização para acessar essa página',
  'You cannot delete a profile that has users': 'Você não pode deletar um perfil que possui usuários',
  '"email" must be a valid email': '"email" deve ser um e-mail válido',
}
