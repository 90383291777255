import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  protected path: string = environment.base_url;
  protected http: HttpClient = inject(HttpClient)
}
