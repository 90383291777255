<div class="modal-error">
  <div class="error-area">
    <div class="icon">
      <fa-icon [icon]="faError"></fa-icon>
    </div>
    <h2>{{ msg }}</h2>
    @if (msg !== 'Você não possui autorização para acessar essa página') {
    <div class="button" (click)="closeModal()">
      <button
        class="btn group relative flex w-full justify-center border border-transparent bg-black px-4 py-2 text-sm font-extrabold font-medium uppercase text-white focus:outline-none focus:ring-2 focus:ring-black focus:ring-offset-2 disabled:bg-gray-400 hover:bg-gray-900 disabled:hover:bg-gray-400">
        FECHAR
      </button>
    </div>
    }
  </div>
</div>
