import { Injectable, inject } from '@angular/core';
import { Token } from '../enum/auth.enum';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService extends HttpService {
  public isAuth: boolean = true;

  private router: Router = inject(Router)

  public login(
    formLogin: any
  ): Observable<any> {
    return this.http.post(`${this.path}/users/auth`, formLogin)
  }

  public logout(
  ): void {
    let redirectRoute = '/auth';
    this.deleteToken();
    setTimeout(() => {
      this.router.navigate([redirectRoute])
    }, 500)

  }

  public acceptTerms(): Observable<any> {
    const user = JSON.parse(localStorage.getItem(Token.USER) || '{}');

    return this.http.patch(`${this.path}/users/accept-terms/${user.userId}`, {});
  }

  setToken(tokens: { access: string; user: any }): void {
    localStorage.setItem(Token.ACCESS_TOKEN, tokens.access);
    localStorage.setItem(Token.USER, JSON.stringify(tokens.user));
  }

  deleteToken(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
}


