import { DatePipe } from "@angular/common";
import { Injectable, inject } from "@angular/core";
import { endOfWeek, startOfWeek, startOfMonth, endOfMonth, startOfYear, endOfYear, format, addDays, addWeeks, addMonths, addYears, parse } from "date-fns";
import { ptBR } from 'date-fns/locale';



@Injectable({
  providedIn: 'root'
})
export class ConvertDate {

  private datePipe: DatePipe = inject(DatePipe);

  private localeUsCa: string = 'en-ca';
  private localePtBr: string = 'pt-br';

  ptBr(date: any): string {
    let formattedDate: any = this.datePipe.transform(date, 'dd/MM/YYYY')
    return formattedDate
  }

  ptBr2(date: any): string {
    const dateFor = format(date, "dd/MM/yyyy", { locale: ptBR });
    return dateFor
  }

  startWeek(date: Date): Date {
    return startOfWeek(date)
  }
  endWeek(date: Date): Date {
    return endOfWeek(date);
  }

  startMonth(date: Date): Date {
    return startOfMonth(date);
  }

  endMonth(date: Date): Date {
    return endOfMonth(date);
  }

  startYear(date: Date): Date {
    return startOfYear(date);
  }

  endYear(date: Date): Date {
    return endOfYear(date);
  }

  formatDateUs(date: Date): string {
    return date.toLocaleDateString(this.localeUsCa);
  }

  formatDateBr(date: Date): string {
    return date.toLocaleDateString(this.localePtBr);
  }

  formatDateBrToUs(date: string) {
    const data = parse(date, 'dd/MM/yyyy', new Date());
    const dataUS = format(data, 'yyyy-MM-dd');
    return dataUS;
  }


  getMonthString(date: Date): string {
    const dateString = format(date, 'LLLL', { locale: ptBR })
    const retorno = dateString.charAt(0).toUpperCase() + dateString.slice(1)
    return retorno;
  }

  getYearString(date: Date): string {
    return format(date, 'yyyy');
  }

  startLastWeek(date: Date, count: number): Date {
    return addWeeks(this.startWeek(date), count);
  }

  endLastWeek(date: Date, count: number): Date {
    return addWeeks(this.endWeek(date), count);
  }

  selectDays(date: Date, count: number): Date {
    return addDays(date, count);
  }

  dayString(date: Date): string {
    return format(date, "d 'de' MMMM 'de' yyyy", { locale: ptBR });
  }

  selectMonthStartDay(date: Date, count: number) {
    return addMonths(this.startMonth(date), count);
  }

  selectMonthEndDay(date: Date, count: number) {
    return addMonths(this.endMonth(date), count);
  }

  selectYearStartDay(date: Date, count: number) {
    return addYears(this.startYear(date), count);
  }
  selectYearEndDay(date: Date, count: number) {
    return addYears(this.endYear(date), count);
  }

}
