import { Injectable } from "@angular/core";
import {  Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})

export class ErrorModalService {
  public errorModal$: Subject<any> = new Subject();
  public afterModal$: Subject<any> = new Subject();
  public closeModal$: Subject<any> = new Subject();
}

