import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private excecoes: string[] = [
    'https://cheil-backend-dev.s3.sa-east-1.amazonaws.com/credits/credits_model.csv',
    'https://cheil-backend-prd.s3.sa-east-1.amazonaws.com/credits/credits_model.csv',
    'https://cheil-backend-hlg.s3.sa-east-1.amazonaws.com/credits/credits_model.csv'
  ];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('token');

    if (this.excecoes.some(url => request.url.includes(url))) {
      return next.handle(request);
    }

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });

    return next.handle(request);
  }
}
