import {  Component, NgZone, OnDestroy, OnInit, ViewContainerRef, inject } from "@angular/core";
import { ErrorModalService } from "./error-modal.service";
import { Subject, takeUntil } from "rxjs";
import { ErrorModal } from "./error-modal.component";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'error-build',
  template: '',
  standalone: true,
  imports: [CommonModule]
})
export class ErrorBuild implements OnInit, OnDestroy {

  private vcr: ViewContainerRef = inject(ViewContainerRef);
  private service: ErrorModalService = inject(ErrorModalService);
  private readonly destroy$: Subject<any> = new Subject();
  private zone: NgZone = inject(NgZone);

  ngOnInit(): void {
    this.service.errorModal$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (msg: string) => {
          if (!msg) return
          const ref = this.vcr.createComponent(ErrorModal);
          this.zone.run(() => {
            ref.changeDetectorRef.detectChanges();
            ref.instance.msg = msg
            this.service.afterModal$.next(msg)
          })
        }
      })

    this.service.closeModal$.asObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((close: boolean) => {
        if (close) this.vcr.clear();
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
