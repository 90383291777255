import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  public updateName$: BehaviorSubject<string> = new BehaviorSubject('')

}
