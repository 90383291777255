import { ErrorHandler, Injectable, inject } from "@angular/core";
import { ERRORS } from "./errors";
import { ErrorModalService } from "../components/error-modal/error-modal.service";

@Injectable()
export class ErrorService implements ErrorHandler {

  private errorService: ErrorModalService = inject(ErrorModalService);

  // erros que não devem aparecer para o usuário
  private exceptionErrors: Array<string> = [
    'JWT Token is missing',
    'Invalid JWT Token'
  ]

  handleError(error: any): void {
    if (!this.exceptionErrors.includes(error.message)) this.errorService.errorModal$.next(ERRORS[error.message])
  }
}
