import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from '../services/session.service';
import { Token } from '../enum/auth.enum';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private router: Router = inject(Router);

  constructor(private session: SessionService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      if (this.session.isAuth) {
        const user = JSON.parse(localStorage.getItem(Token.USER) || '{}');
        if (user.acceptedTerms === null) {
          this.router.navigate(['/auth/accept-terms']);
        }

        const JWT = localStorage.getItem(Token.ACCESS_TOKEN);
        if (JWT) {
          const jwtPayload = JSON.parse(window.atob(JWT.split('.')[1]));
          const expiration = jwtPayload.exp;
          if (Date.now() >= expiration * 1000) {
            this.session.logout();
            resolve(false);
          }
          resolve(true);
        }
        else{
          this.session.logout();
          resolve(false);
        }
      } else {
        this.session.logout();
        resolve(false);
      }
    });
  }
}
