import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient } from "@angular/common/http";
import {  ApplicationConfig, ErrorHandler, importProvidersFrom } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { provideAnimations } from "@angular/platform-browser/animations";
import { AuthInterceptor } from "./modules/auth/interceptor/auth.interceptor";
import { ErrorService } from "./shared/errors/error.service";7

export const appConfig: ApplicationConfig = {
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }, importProvidersFrom(BrowserModule, HttpClientModule, AppRoutingModule), provideHttpClient(), provideAnimations(), { provide: ErrorHandler, useClass: ErrorService }]
}
