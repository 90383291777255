import { Component } from '@angular/core';
import { ThemeService } from './core/services/theme.service';
import { RouterOutlet } from '@angular/router';
import { DatePipe, NgClass } from '@angular/common';
import { ResponsiveHelperComponent } from './shared/components/responsive-helper/responsive-helper.component';
import {  HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UpdateService } from './shared/services/update.service';
import { ConvertDate } from './shared/components/convert-date/convert-date';
import { DateFnsModule } from 'ngx-date-fns';
import { FormsModule } from '@angular/forms';
import { ErrorBuild } from './shared/components/error-modal/error.build';
import { ErrorModal } from './shared/components/error-modal/error-modal.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    RouterOutlet,
    ResponsiveHelperComponent,
    HttpClientModule,
    FontAwesomeModule,
    DateFnsModule,
    ErrorBuild,
    ErrorModal
  ],
  providers: [
    UpdateService,
    ConvertDate,
    DatePipe,
    FormsModule]
})
export class AppComponent {
  title = 'CROS';

  constructor(public themeService: ThemeService) { }
}
