export function CHECK_URL() {
  const current: any = window.location.href;
  if (current.match('localhost') || current.match('cheil-frontend-dev-alb') || current.match('cheilcros-web-dev')) {
    return urls['dev']
  }
  if (current.match('cheilcros-web-hlg')) {
    return urls['homolog']
  }
  if (current.match('cheilcros.com')) {
    return urls['prod']
  } else {
    return urls['dev']
  }

  // return current.match('localhost') || current.match('cheil-frontend-dev-alb') ? urls['dev'] : current.match('cheilcros-web-hlg') ? urls['homolog'] : urls['prod']
}

const urls = {
  'prod': 'https://api.cheilcros.com/api/v1',
  'homolog': 'https://cheilcros-api-hlg.cheilcros.com/api/v1',
  'dev': 'http://localhost:3000/api/v1'
}
