import { CommonModule } from "@angular/common";
import { Component,  Input, OnChanges, OnInit, SimpleChanges, inject } from "@angular/core";
import { ErrorModalService } from "./error-modal.service";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {  faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-modal-error',
  templateUrl: 'error-modal.component.html',
  styleUrl: 'error-modal.component.scss',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule]
})

export class ErrorModal implements OnInit, OnChanges {

  private service: ErrorModalService = inject(ErrorModalService);

  public faClose: IconDefinition = faTimes;
  public faError: IconDefinition = faExclamationTriangle;
  @Input() msg: string = ''
  private interval: any;


  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.service.afterModal$.asObservable()
      .subscribe((msg: string) => {
        this.msg = msg
      })
  }
  closeModal() {
    this.service.closeModal$.next(true)
  }
}
